import CryptoJS from 'crypto-js'

var r = "SWRUSnEwSGtscHVJNm11OGlCJU9PQCF2ZF40SyZ1WFc=",
    i = "JDB2QGtySDdWMg==";

const crypto = {

    base64decoder(t) {
        var e = CryptoJS.enc.Base64.parse(t);
        return CryptoJS.enc.Utf8.stringify(e)
    },

    decrypt(data, suffix) {
        var n = this.base64decoder(r),
            o = this.base64decoder(i);
        
        o = CryptoJS.enc.Utf8.parse(o + suffix);
        n = CryptoJS.enc.Utf8.parse(n);

        return CryptoJS.AES.decrypt(data, n, {
            iv: o,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
            formatter: CryptoJS.format.OpenSSL
        }).toString(CryptoJS.enc.Utf8)
    },

    decryptTitle(title) {
        title = (title + '').replace(/\n*$/g, '').replace(/\n/g, '')
        let result = this.decrypt(title.trim(), "883346").replace(/"/g, "")
        return (result == '') ? title : result
    },

    decryptData(data, suffix) {
        data = (data + '').replace(/\n*$/g, '').replace(/\n/g, '')
        let result = this.decrypt(data.trim(), suffix).replace(/"/g, "")
        return (result == '') ? data : result
    }
}

export default crypto